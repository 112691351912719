<template>
  <Card class="mb-2 flex items-center p-4">
    <div>
      <Icon
        :name="alert.icon"
        class="text-3xl fill-current mr-4"
        :class="[alert.iconColour ? `text-brand-${alert.iconColour}` : '']"
      />
    </div>

    <div class="flex flex-col items-start truncate">
      <p class="font-bold">{{ alert.msg }}</p>
      <p>
        {{ alert.id }}
        <span v-if="alert.delivery">- {{ alert.delivery }}</span>
      </p>
    </div>
  </Card>
</template>

<script>
import { mapActions } from 'pinia'
import { useAlertStore } from '~/stores/alert'

export default {
  name: 'Alert',
  props: {
    alert: {
      type: Object,
      default: () => {}
    },
    bundled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['remove-bundle'],
  data: () => ({
    timeout: null,
    dismissTime: 3000
  }),
  mounted() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    // Timer to dismiss alert
    this.timeout = setTimeout(() => {
      if (this.bundled) {
        // Remove all alerts because a bundle was used instead
        this.empty()
        // Emit event to handle removing bundled alert in parent component
        this.$emit('remove-bundle')
        return
      }

      this.clear()
    }, this.dismissTime)
  },
  methods: {
    ...mapActions(useAlertStore, ['clear', 'empty'])
  }
}
</script>
