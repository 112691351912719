<template>
  <div
    class="fixed left-0 right-0 w-5/6 sm:w-1/3 mx-auto z-60"
    :class="{ 'mt-4 sm:mt-8': alerts.length }"
  >
    <TransitionGroup name="alert-slide-fade" mode="out-in" tag="ul">
      <li
        v-for="(alert, index) in getSingleOrBundledAlerts"
        :key="`${alert}-${index}`"
      >
        <Alert
          :alert="alert"
          :bundled="showBundle"
          @remove-bundle="removeBundledAlert"
        />
      </li>
    </TransitionGroup>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAlertStore } from '~/stores/alert'

export default {
  name: 'AlertContainer',
  data: () => ({
    bundledAlert: []
  }),
  computed: {
    ...mapState(useAlertStore, ['alerts']),
    reversedAlerts() {
      // Alerts reversed in order to stack from oldest to newest
      return [...this.alerts].reverse()
    },
    showBundle() {
      return this.alerts.length > 3
    },
    getSingleOrBundledAlerts() {
      if (this.showBundle) {
        return this.bundledAlert
      }

      return this.reversedAlerts
    }
  },
  watch: {
    alerts({ length }) {
      if (length > 3) {
        this.addBundledAlert(length)
      }
    }
  },
  methods: {
    removeBundledAlert() {
      this.bundledAlert.splice(0, 1)
    },
    addBundledAlert(length) {
      // When length changes, a new bundled alert list is created with a single bundled alert
      // The list will never grow in size but will be replaced with a new bundled alert when length changes
      this.bundledAlert = [
        {
          msg: `${length} Course areas matched`,
          icon: 'IconCheckmark'
        }
      ]
    }
  }
}
</script>
