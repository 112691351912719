import { defineStore } from 'pinia'

interface State {
  activeNav: number[]
  isMobileNavActive: boolean
  profileMenuActive: boolean
}

export const useGlobalNavStore = defineStore('globalNav', {
  state: (): State => ({
    activeNav: [],
    isMobileNavActive: false,
    profileMenuActive: false
  }),
  getters: {
    isNavActive: (state) => (level: number, index: number) =>
      state.activeNav.length >= level && state.activeNav[level] === index,
    hasActiveNav: (state) => (level: number) =>
      state.activeNav.length >= level && state.activeNav[level] > -1
  },
  actions: {
    openNav({ level, index }: { level: number; index: number }) {
      // Close profile navigation when nav clicked
      this.profileMenuActive = false
      // should not occur, however in the off chance that the level exceeds the available
      // length of the activeNav array, pad the array with -1 to reach to target length
      while (this.activeNav.length < level) {
        this.activeNav.push(-1)
      }

      // slicing handles cases where a user clicks on a nav item that is at a higher
      // level, e.g. after navigating to Courses > Pathways, the user clicks on Help.
      const newNav = [...this.activeNav].slice(0, level)

      // add the index of the nav item that should be opened
      newNav.push(index)

      // update the activeNav state
      this.activeNav = [...newNav]
    },

    closeNav() {
      this.activeNav = []
      this.isMobileNavActive = false
    },

    navigateUp() {
      if (this.activeNav.length) this.activeNav.pop()
    },

    toggleMobileNav() {
      this.isMobileNavActive = !this.isMobileNavActive
      if (!this.isMobileNavActive) {
        this.closeNav()
      }
    },
    toggleProfileNav(payload: boolean) {
      this.profileMenuActive =
        payload === undefined ? !this.profileMenuActive : payload
      this.closeNav()
    },

    setMobileNav(payload: boolean) {
      this.isMobileNavActive = payload
      if (!payload) {
        this.closeNav()
      }
    }
  }
})
